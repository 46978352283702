import compare_html_attribute_lists from './compare_html_attribute_lists';
import text_content_of from './text_content_of';
import find_text_matching from './find_text_matching';

// Compare the lhs and rhs (both lists of hyntax AST elements) and populate
// the list of marked_areas with information about the differences between
// those elements.
export default function compare_html_tag_lists(lhs, rhs, marked_areas) {
  for( let i = 0; i < rhs.length; i += 1 ) {
    if( rhs[i].nodeType==="text" ) {
      const rhs_text = text_content_of(rhs[i]);
      const match = find_text_matching(lhs, rhs_text);
      if( !match && rhs[i].content.value && rhs_text && rhs_text.trim() !== "" ) {
        marked_areas.push({
          type: "mismatch",
          start: rhs[i].content.value.startPosition,
          end: rhs[i].content.value.endPosition,
        });
      }
    } else if( rhs[i].nodeType === "tag" ) {
      const match = find_tag_matching(lhs, id_of_tag(rhs[i]), i, rhs[i].content.name);
      if( match ) {
        if( rhs[i].content.attributes ) {
          compare_html_attribute_lists(
            match.content.attributes,
            rhs[i].content.attributes,
            marked_areas
          );
        }
        if( rhs[i].content.children ) {
          compare_html_tag_lists(
            match.content.children,
            rhs[i].content.children,
            marked_areas
          );
        }
      } else {
        marked_areas.push({
          type: "missing",
          start: rhs[i].content.openStart.startPosition,
          end: rhs[i].content.selfClosing ? rhs[i].content.openEnd.endPosition : rhs[i].content.close.endPosition,
        });
      }
    }
  }
}

// Return the ID of a hyntax AST tag element, if it has one.
function id_of_tag(hyntax_tag) {
  if( hyntax_tag.content.attributes ) {
    for( let attr of hyntax_tag.content.attributes ) {
      if( attr.key.content === "id" ) {
        return attr.value && attr.value.content;
      }
    }
  }
}

// Find the element in a list of hyntax AST tag elements that that has (1)
// either the same ID or position and (2) the same tag name.
function find_tag_matching(lhs, id, idx, tag_name) {
  if( !lhs ) {
    return null;
  }

  for( let i = 0; i < lhs.length; i++ ) {
    if( lhs[i].nodeType !== "tag" )
      continue;

    if( lhs[i].content.name !== tag_name ) {
      continue;
    }

    const found_id = id_of_tag( lhs[i] );
    if( found_id && id && found_id !== id ) {
      continue;
    }

    if( !found_id && !id && i !== idx ) {
      continue;
    }

    return lhs[i];
  }

  return null;
}
