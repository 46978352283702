import text_content_of from './text_content_of';

// Find a text element in the lhs (a list of hyntax elements) that matches
// the specified text.
export default function find_text_matching(lhs, text) {
  if( !lhs ) {
    return null;
  }

  for( let i = 0; i < lhs.length; i += 1 ) {
    if( lhs[i].nodeType === "text" && text_content_of(lhs[i]) === text ) {
      return lhs[i];
    }
  }

  return null;
}
