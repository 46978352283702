import { tokenize, constructTree } from 'hyntax';
import compare_html_tag_lists from './compare_html_tag_lists';

// Compare the lhs and rhs (both strings representing HTML documents) and populate
// the list of marked_areas with information about the differences between those two
// documents.
export default function compare_html(lhs, rhs, marked_areas) {
  const lhs_tokens = tokenize(lhs).tokens;
  const rhs_tokens = tokenize(rhs).tokens;

  const lhs_ast = constructTree(lhs_tokens).ast;
  const rhs_ast = constructTree(rhs_tokens).ast;

  compare_html_documents(lhs_ast, rhs_ast, marked_areas);

  return rhs_tokens;
}

function compare_html_documents(lhs, rhs, marked_areas) {
  if( rhs.nodeType === "document" ) {
    compare_html_tag_lists(lhs.content.children || [], rhs.content.children || [], marked_areas);
  } else {
    console.warning("Root node type is not 'document.'")
  }
}
