import React from 'react';
import './HtmlCompare.css';
import TextSegment from './TextSegment';
import { LHS, RHS } from './initial_values';
import compare_html from './compare_html';

/// A widget that compares two HTML documents.
class HtmlCompare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lhs_text: LHS,
      rhs_text: RHS,
      text_segments: [],
      reverse: false,
    };

    Promise.resolve().then(this.recalculate_comparison.bind(this)).catch(console.error);
  }

  lhs_changed(event) {
    this.setState({
      lhs_text: event.target.value,
    });

    Promise.resolve().then(this.recalculate_comparison.bind(this)).catch(console.error);
  }

  rhs_changed(event) {
    this.setState({
      rhs_text: event.target.value,
    });

    Promise.resolve().then(this.recalculate_comparison.bind(this)).catch(console.error);
  }

  toggle_reverse(event) {
    this.setState({
      reverse: event.target.checked,
    });

    Promise.resolve().then(this.recalculate_comparison.bind(this)).catch(console.error);
  }

  handle_file_drag_over(side, ev) {
    ev.preventDefault();
  }

  async handle_file_drop(side, ev) {
    ev.preventDefault();

    for (var i = 0; i < ev.dataTransfer.items.length; i++) {
      if(ev.dataTransfer.items[i].kind === 'file') {
        const file = ev.dataTransfer.items[i].getAsFile();
        this.drop_text(side, await file.text());
        return;
      } else if(ev.dataTransfer.items[i].kind === 'string' &&
          ["text/html","text/xml"].includes(ev.dataTransfer.items[i].type)) {
        ev.dataTransfer.items[i].getAsString(s => this.drop_text(side, s));
        return;
      }
    }
  }

  drop_text(side, text) {
    let state = {};

    if( side === "rhs" ) {
      state.rhs_text = text;
    } else if( side === "lhs" ) {
      state.lhs_text = text;
    } else {
      throw new Error("not lhs or rhs: " + side);
    }

    this.setState(state);

    Promise.resolve().then(this.recalculate_comparison.bind(this)).catch(console.error);
  }

  recalculate_comparison() {
    let marked_areas = [];
    let ast = null;
    let text = "";

    if( this.state.reverse ) {
      compare_html(this.state.rhs_text, this.state.lhs_text, marked_areas);
      text = this.state.lhs_text;
    } else {
      compare_html(this.state.lhs_text, this.state.rhs_text, marked_areas);
      text = this.state.rhs_text;
    }

    this.setState({
      ast: JSON.stringify(ast),
      marked_areas: JSON.stringify(marked_areas),
      text_segments: render_diff_segments(text, marked_areas),
    });
  }

  render() {
    return (
      <div className="HtmlCompare">
       <div className="HtmlCompare-inputs">
          <textarea
            id="lhs"
            onChange={this.lhs_changed.bind(this)}
            value={this.state.lhs_text}>
          </textarea>
          <textarea
            id="rhs"
            onChange={this.rhs_changed.bind(this)}
            value={this.state.rhs_text}>
          </textarea>
        </div>
        <div className="HtmlCompare-drop">
          <div className="lhs"
            onDrop={this.handle_file_drop.bind(this, "lhs")}
            onDragOver={this.handle_file_drag_over.bind(this, "lhs")}>
            Drop File Here
          </div>
          <div className="rhs"
            onDrop={this.handle_file_drop.bind(this, "rhs")}
            onDragOver={this.handle_file_drag_over.bind(this, "rhs")}>
            Drop File Here
          </div>
        </div>
        <div className="HtmlCompare-controls">
          <label><input type="checkbox" checked={this.state.reverse} onChange={this.toggle_reverse.bind(this)}/>Reverse</label>
        </div>
        <div className="HtmlCompare-output">
          <p className="HtmlCompare-debug">{this.state.marked_areas}</p>
          <div className={this.state.reverse ? "HtmlCompare-reverse" : "HtmlCompare-forward"}>
            <p>{this.state.text_segments.map(segment => (<TextSegment style={segment.style} slice={segment.slice} was={segment.was}/>))}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default HtmlCompare;

// Marks up some html_text (a string) according to some marked_areas (a list
// of { start: int, end: int, was: optional string, type: string }.
//
// The result is a list of text segments (a list of { slice: string, style: string, was: optional string }).
// These text segments can be concatenated with styling to render a document.
function render_diff_segments(html_text, marked_areas) {
  marked_areas.sort((a,b) => a.start - b.start);

  marked_areas.reverse();

  let idx = 0;
  let next_segment = null;
  const text_segments = [];

  while( marked_areas.length > 0 ) {
    next_segment = marked_areas.pop();

    if( next_segment.start < idx ) {
      continue;
    }

    text_segments.push({
      style: "plain",
      slice: html_text.slice(idx, next_segment.start),
    });

    text_segments.push({
      style: next_segment.type,
      slice: html_text.slice(next_segment.start, next_segment.end+1),
      was: next_segment.was,
    });

    idx = next_segment.end+1;
  }

  text_segments.push({
    style: "plain",
    slice: html_text.slice(idx, html_text.length),
  });

  return text_segments;
}
